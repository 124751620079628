<template>
  <router-view />
</template>

<script>
export default {
  name: 'Layout',
  data () {
    return {
    }
  },
  mounted () { },
  methods: {}
}
</script>

<style scoped>
</style>