import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import 'amfe-flexible' //px转换rem
import '@/assets/style/common.less'
import '@/assets/style/default.less'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import http from '@/network/request'
import store from '@/store'
import * as echarts from 'echarts'
// 挂载全局
Vue.prototype.$http = http
Vue.prototype.$store = store
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
