import Vue from 'vue'
import Vuex from 'vuex'
import cloneDeep from 'lodash/cloneDeep' //深拷贝  clone 浅拷贝
import user from './modules/user'
import menu from './modules/menu'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true, //开启namespace:true 该模块就成为命名空间模块了 false 全局命名空间
  //存储状态。也就是变量； this.$store.state.全局数据名称 (count)   import { mapState } from 'vuex'     ...mapState(['count'])
  state: {},
  //派生状态。加工state 成员给外界，也就是get; 组件使用 this.$store.getters.fullInfo    ...mapGetters(['findConfigs'])
  getters: {},
  //提交状态修改，也就是set; 组件调用 this.$store.commit('findConfigs','new token')   ...mapMutations(['findConfigs'])
  mutations: {
    // 重置vuex本地储存状态
    resetStore(state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key])
      })
    },
  },
  //和mutations类似。不过actions支持异步操作 组件中使用  this.$store.dispatch('aEdit','new TOKEN')   ...mapActions(['aEdit'])
  actions: {},
  //store的子模块，内容就相当于是store的一个实例。
  modules: {
    user,
    menu,
  },
})
