import http from '@/network/request'
export default {
  namespaced: true, //访问地址都要加一个 menu/
  state: () => ({
    dynamicMenuRoutesHasAdded: false, // 动态(菜单)路由是否已经添加的状态标示（用于判断是否需要重新拉取数据并进行动态添加操作）
    dynamicMenuRoutes: [], // 动态(菜单)路由列表
    menuList: [], // 左侧菜单列表（后台返回，未做处理）
    dictList: [], //字典列表
  }),
  mutations: {
    SET_CONFIG(state, data) {
      state.dictList = data
    },
    SET_MENU(state, data) {
      state.menuList = data
    },
    SET_ROUTES(state, data) {
      state.dynamicMenuRoutes = data
    },
    SET_HAS(state, data) {
      state.dynamicMenuRoutesHasAdded = data
    },
  },
  actions: {
    // 获取字典列表, 添加并全局变量保存
    findConfigs(context) {
      http.get('/userInfo/findConfigs').then(({ data: res }) => {
        context.commit('SET_CONFIG', res.data)
				  console.log("打印",res);
      }).catch(() => {})
    },
    // 获取菜单列表, 添加并全局变量保存
    findPermissionList(context) {
      http.get('/permission/findPermissionList').then(({ data: res }) => {
		  console.log("打印",res);
        context.commit('SET_MENU', res.data)
      }).catch(() => {})
    },
  },
  getters: {},
}
