export default {
  state: () => ({
    userInfo: {},
  }),
  mutations: {  
    //设置用户信息
    SET_USER (state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: { 
    setUser(context){
      context.commit('SET_USER')
    }, 
  },
  getters: { 
    getUser (state) {
      return state.userInfo
    }
  }
}
