import Vue from 'vue'
import VueRouter from 'vue-router'
import http from '@/network/request'
import { isURL } from '@/utils/util'
import store from '@/store'
// import { moduleRoutes } from './menus'
const myAppConfig = window._config

Vue.use(VueRouter)

/* 空白 Layout */
import Layout from '@/components/Frame/layout'

const ProjectFrame = () => import('@/views/ProjectFrame')
const IframePage = () => import('@/views/IframePage')

// 页面路由(独立页面)
export const pageRoutes = [
  {
    path: '/404',
    component: () => import('@/views/pages/404'),
    name: '404',
    meta: { title: '404未找到' },
    beforeEnter(to, from, next) {
      // 拦截处理特殊业务场景
      // 如果, 重定向路由包含__双下划线, 为临时添加路由
      if (/__.*/.test(to.redirectedFrom)) {
        return next(to.redirectedFrom.replace(/__.*/, ''))
      }
      next()
    },
  },
  {
    path: '/login',
    component: () => import('@/views/pages/login'),
    name: 'login',
    meta: { title: '登录' },
  },
]

// // 模块路由(基于主入口布局页面)
export const moduleRoutes = {
  path: '/',
  name: 'ProjectFrame',
  component: ProjectFrame,
  redirect: '/home',
  meta: { title: '主入口布局' },
  children: [
    {
      path: '/home',
      component: () => import('@/views/modules/home/home'),
      name: 'home',
      meta: {
        icon: 'el-icon-s-home',
        title: '首页',
        keepAlive: true,
      },
    },
  ],
}

const router = new VueRouter({
  // mode: 'history',   // 访问路径不带#号
  // base: process.env.BASE_URL,   // 配置单页应用的基路径
  mode: 'hash',    // 访问路径带#号
  scrollBehavior: () => ({ y: 0 }),  // return 期望滚动到哪个的位置
  routes: pageRoutes.concat(moduleRoutes),
})

router.beforeEach((to, from, next) => {
  // 添加动态(菜单)路由
  // 已添加或者当前路由为页面路由, 可直接访问
  if (
    myAppConfig.dynamicMenuRoutesHasAdded ||
    fnCurrentRouteIsPageRoute(to, pageRoutes)
  ) {
    return next()
  }
  // 刷新字典
  store.dispatch('menu/findConfigs') 
  // 获取菜单列表, 添加并全局变量保存
  http.get('/permission/findPermissionList').then(({ data: res }) => {
    myAppConfig.menuList = res.data
    store.commit('menu/SET_MENU', res.data) //赋值右侧导航栏
    fnAddDynamicMenuRoutes(res.data)
    next({ ...to, replace: true })
  }).catch(() => {
    next({ name: 'login' })
  })
})

export default router

/**
 * 判断当前路由是否为页面路由
 * @param {*} route 当前路由
 * @param {*} pageRoutes 页面路由
 */
function fnCurrentRouteIsPageRoute(route, pageRoutes = []) {
  var temp = []
  for (var i = 0; i < pageRoutes.length; i++) {
    if (route.path === pageRoutes[i].path) {
      return true
    }
    if (pageRoutes[i].children && pageRoutes[i].children.length >= 1) {
      temp = temp.concat(pageRoutes[i].children)
    }
  }
  return temp.length >= 1 ? fnCurrentRouteIsPageRoute(route, temp) : false
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
  var temp = []
  for (var i = 0; i < menuList.length; i++) {
    let menuItem = menuList[i]
    if (menuItem.children && menuItem.children.length >= 1) {
      temp = temp.concat(menuItem.children)
      continue
    }
    // 组装路由
    var route = {
      path: '',
      component: null,
      name: '',
      redirect: '',
      hidden: true,
      meta: {
        icon: '',
        title: '',
        keepAlive: true,
        iframeURL:'',
      },
    }
    if (menuItem.url) {
      if (isURL(menuItem.url)) {
        route['path'] = route['name'] = `i-${menuItem.id}`
        route['component'] = IframePage
        route['meta']['iframeURL'] = menuItem.url
      } else {
        route['path'] =  menuItem.url
        route['name'] = menuItem.url.replace(/\//g, '-')
        route['component'] = () => import(`@/views/modules${menuItem.url}`)
      }
      route['meta']['title'] = menuItem.name
      route['meta']['icon'] = menuItem.icon
      if (menuItem.buttons) route['meta']['buttons'] = menuItem.buttons
      routes.push(route)
    }
  }
  if (temp.length >= 1) {
    return fnAddDynamicMenuRoutes(temp, routes)
  }
  // 添加路由
  router.addRoutes([
    {
      ...moduleRoutes,
      children: routes,
    }
  ])
  myAppConfig.dynamicMenuRoutes = routes
  myAppConfig.dynamicMenuRoutesHasAdded = true
}