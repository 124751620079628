/*
 *获取URL参数
 */
export function getRequestParams() {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var reg_rewrite = new RegExp('(^|/)' + name + '/([^/]*)(/|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  var q = window.location.pathname.substr(1).match(reg_rewrite)
  if (r != null) {
    return unescape(r[2])
  } else if (q != null) {
    return unescape(q[2])
  } else {
    return null
  }
}
/*
 *时间戳转日期
 */
export function timesTampToTime(timestamp) {
  var date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-'
  var M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + ''
  return Y + M + D
}
//千位分隔符
export function milliFormat(num) {
  return (
    num &&
    num.toString().replace(/\d+/, function (s) {
      return s.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    })
  )
}
/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
    s
  )
}
/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  return /^1[0-9]{10}$/.test(s)
}
/**
 * 电话号码
 * @param {*} s
 */
export function isPhone(s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}
/**
 * URL地址
 * @param {*} s
 */
export function isURL(s) {
  return /^http[s]?:\/\/.*/.test(s)
}
// 检测是否为一个函数
export function isFunction(obj) {
  return typeof obj === 'function' && typeof obj.nodeType !== 'number'
}

// 检测是否为空对象
export function isEmptyObject(obj) {
  if (obj == null) return false
  var keys = Object.keys(obj)
  if (typeof Symbol !== 'undefined') {
    keys = keys.concat(Object.getOwnPropertySymbols(obj))
  }
  return keys.length === 0
}
// 检测是否为数字
export function isNumeric(obj) {
  var type = toType(obj)
  return (type === 'number' || type === 'string') && !isNaN(+obj)
}
// 判断 移动端还是pc端
export function isFacility(type = "pc") {
  if(type == 'pc'){
    var flag = !navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);  
  } else if(type == 'android'){
    var flag = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1; //android终端
  } else if(type == 'ios'){
    var flag = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  }
  return flag;
}
// 函数防抖
export function debounce(func, wait, immediate) {
  if (typeof func !== 'function')
    throw new TypeError('func must be required and be an function!')
  if (typeof wait === 'boolean') {
    immediate = wait
    wait = 300
  }
  if (typeof wait !== 'number') wait = 300
  if (typeof immediate !== 'boolean') immediate = false
  var timer = null,
    result
  return function proxy() {
    var runNow = !timer && immediate,
      params = [].slice.call(arguments),
      self = this
    if (timer) clearTimeout(timer)
    timer = setTimeout(function () {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      !immediate ? (result = func.apply(self, params)) : null
    }, wait)
    runNow ? (result = func.apply(self, params)) : null
    return result
  }
}
// 遍历数组/类数组/对象「支持回调函数返回值处理：返回false则结束循环，这是内置方法forEach/map不具备的」
export function each(obj, callback) {
  typeof callback !== 'function' ? (callback = Function.prototype) : null
  var length,
    i = 0,
    keys = []
  if (isArrayLike(obj)) {
    // 数组或者类数组
    length = obj.length
    for (; i < length; i++) {
      var item = obj[i],
        result = callback.call(item, item, i)
      if (result === false) break
    }
  } else {
    // 对象
    keys = Object.keys(obj)
    typeof Symbol !== 'undefined'
      ? (keys = keys.concat(Object.getOwnPropertySymbols(obj)))
      : null
    i = 0
    length = keys.length
    for (; i < length; i++) {
      var key = keys[i],
        value = obj[key]
      if (callback.call(value, value, key) === false) break
    }
  }
  return obj
}
//生成32位随机字符串
export function randomString(len = 32) {
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz';
  let maxPos = chars.length;
  let character = '';
  for (let i = 0; i < len; i++) {
    character += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return character;
}
